<template>
    <div>
<SubHeader :link1="{ name: 'Slider'}" :link1Name="$t('Sliders')" :linkActiveName="$t('Add')" :linkB1="{ name: 'Slider'}" :linkB1Name="$t('Back')" />

<DoneAlert :msg="$t('Data has been updated')" :visible="ShowDoneAlert" />
<ErrorAlert :msg="errorMgs" :visible="ShowError" />
<b-container  class=" mt-5" >
  <div class="text-right mb-3">
  <div>
    {{$t('Language')}} : {{this.form.lang_id}}
      </div>
        </div>
 <div class="text-right mb-3">
  <div>
   
    <b-button-group>
      <b-button v-for="translation in translations" :key="translation.id+'5sdd'" @click="getTranslation(translation.id)">{{translation.lang_id}}</b-button>
      
    </b-button-group>
  </div>
 
</div>
<b-card bg-variant="light" v-if="showForm" :header="$t('Slider') " class="text-left">
  
<br>   
<b-form @submit="onSubmit"  v-if="show"  enctype="multipart/form-data">
<b-row>
    <b-col cols="12"  > 
    <div>
    <b-alert :show="ShowError" dismissible variant="danger">
      
    {{errorMgs}}

    </b-alert>
     </div>
     </b-col>
</b-row>
  <b-row class="mb-3 pb-5 border-bottom">
          <b-col v-if="previewImage"  cols="6" >  <h5 >{{$t('Image')}}</h5>   <b-img :src="previewImage" fluid></b-img> </b-col>    
            
        </b-row>
<b-row align-h="center">
   <!--image-->
    
<b-col cols="12" lg="6" xl="6" > 

    
         <!-- input -->
      <b-form-group  :label="$t('Name')" label-for="input-1" :class="{ 'form-group--error': $v.form.name.$error }">
        <b-form-input  v-model.trim="form.name" @input="setTouched('name'),sanitizeTitle(form.name)" type="text" required ></b-form-input>
         <b-form-invalid-feedback  v-if="$v.form.name.$error"  :state="$v.form.name.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
       <!-- input -->
      <b-form-group  label="text 1" label-for="input-1">
        <b-form-textarea   v-model.trim="form.text1" type="text"  rows="3"
      max-rows="6" ></b-form-textarea>
      </b-form-group>
       <!-- input -->
      <b-form-group  label="text 2" label-for="input-1">
        <b-form-textarea   v-model.trim="form.text2" type="text"  rows="3"
      max-rows="6" ></b-form-textarea>
      </b-form-group>
        <!-- input -->
      <b-form-group  label="btn_title" label-for="btn_title" >
        <b-form-input   v-model.trim="form.btn_title"  type="text"  ></b-form-input>
      </b-form-group>
      <!-- input -->
      <b-form-group  :label="$t('Sorting')" label-for="input-1" :class="{ 'form-group--error': $v.form.sort.$error }">
        <b-form-input   v-model.trim="form.sort" @input="setTouched('robot')" type="text" required ></b-form-input>
         <b-form-invalid-feedback v-if="$v.form.sort.$error" :state="$v.form.sort.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
 

    
   
      
   </b-col>

   <b-col cols="12" lg="6" xl="6" > 

      
          <!-- input -->
        <b-form-group  :label="$t('Image')" label-for="input-2">
         <b-form-file v-model="form.image"  :placeholder="$t('uploadImage')" accept="image/jpeg, image/png, image/gif"
          drop-placeholder="Drop file here..."
         ></b-form-file>
           <div v-if="errorsBack.logo" >
            <b-form-invalid-feedback v-for="error in errorsBack.logo" :key="error"   :state="false"> {{error}}</b-form-invalid-feedback>
         </div>
          </b-form-group>
        

            <!-- input -->
            <b-form-group  :label="$t('Status')" label-for="input-3" :class="{ 'form-group--error': $v.form.status.$error }">
            <b-form-select  v-model="form.status" :options="statuses" @input="setTouched('status')" required ></b-form-select>
            <b-form-invalid-feedback v-if="$v.form.status.$error" :state="$v.form.status.required"> {{$t('required_form')}} </b-form-invalid-feedback>
            </b-form-group>
 <!-- input -->



 
  


   </b-col>
   </b-row>
   <b-row>
    <b-col cols="12"  >
   
   <b-form-group  :label="$t('Description')" label-for="input-2">
     <vue-editor useCustomImageHandler @image-added="handleImageAdded"   v-model="form.description"></vue-editor>
   </b-form-group>
        </b-col>
</b-row>
<b-row>
    <b-col cols="12"  >

    <b-button type="submit" variant="info"><b-spinner v-if="isSendingForm" small></b-spinner>  {{$t('Save')}}</b-button>
     </b-col>
</b-row>






    </b-form>
     </b-card>
</b-container>

</div>




</template>

<script>
import SubHeader from '@/components/layout/SubHeader.vue'
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import DoneAlert from '@/components/alerts/DoneAlert.vue'
import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
import { VueEditor } from "vue2-editor";

export default {
    name:'Slider',
        data() {
      return {
        form: {
            name: null,
            image: null,
            btn_title: null,
            text1:null,
            text2:null,
            description:null,
            slug: null,
            sort: null,
            status:null,
            lang_id:this.$store.state.mainLanguage,
        },
        ref_id: null,
        translations :[],
        main_id:null,
        previewImage:"",
        previewCover:"",
        statuses: [ 'active', 'deactivated', 'waiting review'],
        errorsBack:{},
        errorMgs:'',
        ShowError: false,
        show: true,
        ShowDoneAlert:false,
        isSendingForm:false,
        showForm:true,
      }
    },
    components:{
        SubHeader, DoneAlert,ErrorAlert, VueEditor
        
    },
     validations: {
      form:{
      name: {  required },
      sort: {  required },
      status: {  required },
    


    }
  },
 
  /***************** */
      methods: {
      fillForm(Thedata){
        this.form.name = Thedata.name;
        this.form.btn_title = Thedata.btn_title;
        this.form.text1 = Thedata.text1;
        this.form.text2 = Thedata.text2;
        this.form.description = Thedata.description;
        this.form.sort = Thedata.sort;
        this.form.status = Thedata.status;

        if(Thedata.thumbnailmd){  this.previewImage =this.$store.state.backendUrl+Thedata.thumbnailmd;}
      
        this.main_id= Thedata.id;

    //console.log("zxcxz-----------"+ this.form);


      },

     setTouched(theModel) {

       if(theModel == 'status' || theModel == 'all' ){this.$v.form.status.$touch()}
       if(theModel == 'name' || theModel == 'all' ){this.$v.form.name.$touch()}
       if(theModel == 'sort' || theModel == 'all' ){this.$v.form.sort.$touch()}
    },
        fixingOption(json){
          var array=[];
          for (let index = 0; index < json.length; index++) {
            array[index] ={"text":this.$t(json[index].name),"value":json[index].name} ;
            
          }
           return array;
        },
      getCreateData(){
      axios.get(this.$store.state.backendUrl+'api/slider/create?lang_id='+this.form.lang_id , {
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
                if(response.Error){this.ShowError=true;this.errorMgs = this.$t('error_login');}
                else{
                 this.translations = response.data.success.translations;
                 this.form.sort = response.data.success.sort;
                  //this.fillForm(response.data.success.Slider);
                  //this.showForm=true;
               //  console.log(response.data.success);
                }
            })
            .catch( (error) => {
                console.log(error);
                this.ShowError=true;
                this.errorMgs = error;
                
            });
           
      } , 
      getTranslation(theId){
      this.main_id = theId;
      this.showForm = false,
      this.ResetForm(); 
      this.getCreateData();
      },
      onSubmit(event) {
        event.preventDefault()
        if (!this.$v.$invalid) {
        this.errorsBack=[];
        this.isSendingForm = true;
        //alert(JSON.stringify(this.form))
        const formData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
             if(value != null){
               formData.append(key,value)
               console.log(key, value);
              }
        }
        if(this.ref_id){formData.append("ref_id",this.ref_id)}
          
        
      
        axios.post(this.$store.state.backendUrl+'api/slider',formData, {
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
               
                  //console.log(response.data.success);
                  this.isSendingForm = false;
                  this.ShowError=false;
                  this.errorMgs = "";
                  this.ShowDoneAlert = true;
                   this.ResetForm();
                  setTimeout(()=>{     
                  this.$router.push({ name: 'Slider_edit', params: { id: response.data.success.Slider.id} })
                  }, 3000);
            })
            .catch( (error) => {
                console.log(error.response);
                this.ShowError=true;
                this.errorMgs = error;
                this.isSendingForm = false;
                 if(error){
                    this.errorsBack = error.response.data.errors;
                     console.log(error.response.data.errors);
                  }
            });
           }
      },
     
      ResetForm() {
        
        // Reset our form values
       
        this.form.name = null,
        this.form.btn_title = null,
        this.form.text1 = null,
        this.form.text2 = null,
        this.form.status = null,
        this.form.image = null,
        this.form.sort = null,
        this.form.description = null,
        this.previewImage= "",
        this.translations =[],

        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },
       handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      var formData = new FormData();
      formData.append("image", file);

    
      axios.post(this.$store.state.backendUrl+'api/slider_uploadImage',formData, {
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
        .then(result => {
          let url = result.data.url; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        })
        .catch(err => {
          console.log(err);
        });
    },
    /////////////////////////////////////////////////////
    sanitizeTitle: function(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Trim the last whitespace
      slug = titleLower.replace(/\s*$/g, '');
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-');
      this.form.slug =slug;
      return slug;
    }
    },
 
    mounted() {
 
      if(this.$route.query.lang_id){
         this.form.lang_id =this.$route.query.lang_id;
      }
      if(this.$route.query.ref_id){
         this.ref_id =this.$route.query.ref_id;
      }
      
      this.getCreateData();
      //this.form.lang_id = this.$i18n.locale;
    }
    
}
</script>